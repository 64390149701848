import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import IdleVue from 'idle-vue'
// import { generalMethods } from './mixins/generic-functions'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Idle timeout
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 300000, // 5 minutes
  startAtIdle: false,
})

// Vue.prototype.$generalMethods = generalMethods

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@/assets/scss/nprogress.css')

Vue.config.productionTip = false

Vue.prototype.$siteUrl = process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:8000/api/'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

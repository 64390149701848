import { $themeBreakpoints } from '@themeConfig'
import jwt from '@/auth/jwt/sclUseJwt'
import router from '@/router'
import { generalMethods } from '@/mixins/generic-functions'
// import { resolve } from 'core-js/fn/promise'

function setTokenTimeout(vuexContext, doAccessToken) {
  let timeoutValue = 0
  if (doAccessToken) {
    timeoutValue = vuexContext.state.accessExpiry
  } else {
    timeoutValue = vuexContext.state.refreshExpiry
  }

  const timer = setTimeout(() => {
    const refreshToken = jwt.getRefreshToken()
    const params = JSON.stringify({ refresh: refreshToken, user: vuexContext.state.username })
    jwt.axiosIns.post('/tokenupdate/', params)
      .then(response => {
        jwt.setToken(response.data.access)
        jwt.setRefreshToken(response.data.refresh)
        vuexContext.commit('setAccessExpiry', response.data.accessexp)
        vuexContext.commit('setRefreshExpiry', response.data.refreshexp)
        setTokenTimeout(vuexContext, doAccessToken)
      })
      .catch(e => {
        console.error(e)
        generalMethods.warningMessage('Your session has timed out')
        vuexContext.commit('clearToken')
        router.push('/login')
      })
  }, timeoutValue * 1000)

  if (doAccessToken) {
    vuexContext.commit('saveAccessTimer', timer)
  } else {
    vuexContext.commit('saveRefreshTimer', timer)
  }
}

function getWeekOfYear() {
  // works based off week starting Monday
  const currentDate = new Date()
  const dateForCalc = new Date(currentDate.valueOf())
  // console.log('current date: ', dateForCalc)
  // ISO week date weeks start on Monday, but Javascript date starts on Sunday, so correct the day number
  const dayNumber = (currentDate.getDay() + 6) % 7
  // console.log('Iniital day number: ', dayNumber)
  // ISO 8601 states that week 1 is the week with the first Thursday of that year
  // Set the target date to the Thursday in the target week
  dateForCalc.setDate(dateForCalc.getDate() - dayNumber + 3)
  // returns the number of milliseconds between 1 January 1970 00:00:00 UTC and the given date
  const firstThursday = dateForCalc.valueOf()
  // Set month to 0:January, day:1
  dateForCalc.setMonth(0, 1)
  // Check if it's a Thursday and correct the date to the next Thursday if needed
  if (dateForCalc.getDay() !== 4) {
    const newDateVal = 1 + (((4 - dateForCalc.getDay()) + 7) % 7)
    dateForCalc.setMonth(0, newDateVal)
  }

  // The week number is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
  let weekOfYear = 1 + Math.ceil((firstThursday - dateForCalc) / 604800000)
  // Week of the year is less than 15 it's the start of a new year (not financial year), so need to adjust
  if (weekOfYear < 15) {
    weekOfYear += 53 // Add 53 weeks
  }

  weekOfYear -= 14 // Minus 14 weeks for financial year - Temporary fix to set week of SCL financial year

  return weekOfYear
}

function getCurrentYear(weekno) {
  if (weekno > 80) {
    return 'error'
  }

  return 1 // must return index of year!
  // let returnVal
  // const currentDate = new Date()
  // if (currentDate.getMonth() >= 3 && weekno < 50) {
  //   returnVal = String(currentDate.getFullYear())
  //   return returnVal.concat('-', String(currentDate.getFullYear() + 1))
  // }
  // returnVal = String(currentDate.getFullYear() - 1)
  // return returnVal.concat('-', String(currentDate.getFullYear()))
}

function CreatePayBatchDetails(batchid, date, filename, itemcount, status, week, year) {
  this.id = batchid
  this.date = date
  this.filename = filename
  this.itemcount = itemcount
  this.status = status
  this.week = week
  this.year = year
}

function CreateOperativeDetails(workpermit, tradename, vatreg, vatno, sclinv, scltax, sageId, clientId, feeOverride, cscscard, rtwapproved) {
  this.workpermit = workpermit
  this.tradename = tradename
  this.vatreg = vatreg
  this.vatno = vatno
  this.sclinv = sclinv
  this.scltax = scltax
  this.sage_id = sageId
  this.client_ent_id = clientId
  this.feeoverride = feeOverride
  this.cscscard = cscscard
  this.rtwapproved = rtwapproved
}

function CreateBankDetails(accno, accname, sortcode, bacsref) {
  this.bnkaccno = accno
  this.bnkaccname = accname
  this.bnksortcode = sortcode
  this.bacsref = bacsref
}

function CreateCISDetails(cisreg, utr, taxrate) {
  this.cisreg = cisreg
  this.utr = utr
  this.taxrate = taxrate
}

function CreateSageSyncDetails(sageId) {
  this.sync_id = sageId
}

function CreateGDPRDetails(auth) {
  this.auth = auth
}

function CreateTradeDetails(tradeId) {
  this.trade_id_id = tradeId
}

function CreateVendorDetails() {
}

function CreateEntityClients(feeRate) {
  this.feerate = feeRate
}

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loggedIn: false,
    accessTimer: null,
    refreshTimer: null,
    accessExpiry: 0,
    refreshExpiry: 0,
    username: '',
    operativeValidated: null,
    trades: [],
    jobsources: [],
    currentPaymentSearchCriteria: {
      selectedClient: '',
      selectedWeekEnd: '',
      selectedWeek: '',
      selectedYear: '',
    },

    operativeSearchCriteria: {
      selectedClient: 'ALL',
      selectedStatus: 'ACTIVE',
      selectedTrade: 'ALL',
      selectedLastWorked: '',
      limitToApplicants: false,
    },

    currentOperativeDetails: {
      ent_id: '-1',
      title: '',
      firstname: '',
      lastname: '',
      dob: 'new',
      status: '',
      ninumber: '',
      nationality: '',
      address: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      county: '',
      country: '',
      email: '',
      mobphone: '',
      homephone: '',
      name: '',
      updated: '',
      dayRate: '',
      comp_reg_no: '',
      comptype: '',
      trade: '',
      trade_id: -1,
      trade_rate: '',
      sage_exp_enable: false,
      jobsource_id_id: -1,
      operativedetails: [new CreateOperativeDetails('', '', false, '', false, false, '', -1, 0, false, false)],
      bankdetails: [new CreateBankDetails('', '', '', '')],
      cisdetails: [new CreateCISDetails(false, '', '')],
      gdprdetails: [new CreateGDPRDetails(false)],
      tradedetails: [new CreateTradeDetails(-1)],
      entitysync: [new CreateSageSyncDetails('')],
      vendordetails: [new CreateVendorDetails('')],
      entityclients: [new CreateEntityClients(0)],
    },
    paymentsForCurrentClient: {},
    operativesForCurrentClient: [],
    clients: [],
    searchClients: [],
    searchYears: [],
  },
  getters: {
    getLoggedIn(state) {
      return state.loggedIn
    },

    getClients(state) {
      return state.clients
    },

    getSearchYears(state) {
      return state.searchYears
    },

    getSearchClients(state) {
      return state.searchClients
    },

    getPaymentsForCurrentClient(state) {
      return state.paymentsForCurrentClient
    },

    getTrades(state) {
      return state.trades
    },

    getJobsources(state) {
      return state.jobsources
    },

    getTaxFeeTotal(state) {
      return state.taxfeetotal
    },

    getOtherFeesTotal(state) {
      return state.otherfeestotal
    },

    getOperativesForCurrentClient(state) {
      return state.operativesForCurrentClient
    },

    getOperativeValidation(state) {
      return state.operativeValidated
    },

    getCurrentOperative(state) {
      return state.currentOperativeDetails
    },

    getCurrentPaymentSearchCriteria(state) {
      return state.currentPaymentSearchCriteria
    },

    getOperativeSearchCriteria(state) {
      return state.operativeSearchCriteria
    },

    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },

    getCurrentPaymentYear(state) {
      return state.currentPaymentSearchCriteria.selectedYear
    },

    getCurrentPaymentWeek(state) {
      return state.currentPaymentSearchCriteria.selectedWeek
    },

    getCurrentPaymentSearchCriteriaAsParams(state) {
      let params = '?'
      if (state.currentPaymentSearchCriteria.selectedYear !== '') {
        params += `year=${state.currentPaymentSearchCriteria.selectedYear}`
      }
      if (state.currentPaymentSearchCriteria.selectedWeek !== '') {
        if (params.length > 1) {
          params += '&'
        }
        params += `week=${state.currentPaymentSearchCriteria.selectedWeek}`
      }
      if (state.currentPaymentSearchCriteria.selectedClient !== 'All clients' && state.currentPaymentSearchCriteria.selectedClient !== '') {
        if (params.length > 1) {
          params += '&'
        }
        const clientId = Number(state.currentPaymentSearchCriteria.selectedClient)
        params += `client=${clientId}`
      }
      if (state.currentPaymentSearchCriteria.selectedWeekEnd !== 'W/e xx/xx/xx' && state.currentPaymentSearchCriteria.selectedWeekEnd !== '') {
        if (params.length > 1) {
          params += '&'
        }
        params += `weekend=${state.currentPaymentSearchCriteria.selectedWeekEnd}`
      }

      return params
    },

    getOperativeSearchCriteriaAsParams(state) {
      return generalMethods.getSearchCriteriaAsParams(state, false)
    },
  },
  mutations: {
    setLoggedIn(state) {
      state.loggedIn = jwt.getToken() !== null
    },

    clearToken(state) {
      state.loggedIn = false
      jwt.setToken('')
      jwt.setRefreshToken('')
      state.username = ''
      state.accessExpiry = 0
      state.refreshExpiry = 0
      if (state.accessTimer !== null) {
        clearTimeout(state.accessTimer)
        state.accessTimer = null
      }
      if (state.refreshTimer !== null) {
        clearTimeout(state.refreshTimer)
        state.refreshTimer = null
      }
    },

    setUsername(state, value) {
      state.username = value
    },
    setAccessExpiry(state, value) {
      state.accessExpiry = value
    },

    setRefreshExpiry(state, value) {
      state.refreshExpiry = value
    },
    saveAccessTimer(state, value) {
      state.accessTimer = value
    },

    saveRefreshTimer(state, value) {
      state.refreshTimer = value
    },

    getClients(state, clientList) {
      state.clients = clientList
    },

    getSearchYears(state, yearList) {
      state.searchYears = yearList
    },

    getSearchClients(state, clientList) {
      state.searchClients = clientList
    },

    getPaymentsForCurrentClient(state, clientOps) {
      state.paymentsForCurrentClient = clientOps
    },

    getTrades(state, trades) {
      state.trades = trades
    },

    getJobsources(state, jobsources) {
      state.jobsources = jobsources
    },

    getOperativeFeeTotals(state, data) {
      if (data.length === 0 || data[0].taxfeetotal === undefined) {
        state.taxfeetotal = ''
        state.otherfeestotal = ''
        return
      }
      state.taxfeetotal = data[0].taxfeetotal
      state.otherfeestotal = data[0].otherfeestotal
    },

    getOperativesForCurrentClient(state, clientOps) {
      state.operativesForCurrentClient = clientOps
    },

    resetOperativeValidation(state) {
      state.operativeValidated = null
    },

    startOperativeValidation(state) {
      state.operativeValidated = true
    },

    failOperativeValidation(state) {
      state.operativeValidated = false
    },

    getCurrentOperative(state, opDetails) {
      state.currentOperativeDetails = opDetails

      if (state.currentOperativeDetails.bankdetails === undefined || state.currentOperativeDetails.bankdetails.length === 0) {
        state.currentOperativeDetails.bankdetails = []
        state.currentOperativeDetails.bankdetails.push(new CreateBankDetails('', '', '', ''))
      }

      if (state.currentOperativeDetails.gdprdetails === undefined || state.currentOperativeDetails.gdprdetails.length === 0) {
        state.currentOperativeDetails.gdprdetails = []
        state.currentOperativeDetails.gdprdetails.push(new CreateGDPRDetails(false))
      }

      if (state.currentOperativeDetails.entitysync === undefined || state.currentOperativeDetails.entitysync.length === 0) {
        state.currentOperativeDetails.entitysync = []
        state.currentOperativeDetails.entitysync.push(new CreateSageSyncDetails(''))
      }

      if (state.currentOperativeDetails.entityclients === undefined || state.currentOperativeDetails.entityclients.length === 0) {
        state.currentOperativeDetails.entityclients = []
        state.currentOperativeDetails.entityclients.push(new CreateEntityClients(0))
      }
    },

    resetCurrentYear(state) {
      state.currentPaymentSearchCriteria.selectedYear = -1
    },

    resetCurrentWeek(state) {
      state.currentPaymentSearchCriteria.selectedWeek = -1
    },

    resetClients(state) {
      state.clients = []
    },

    resetSearchYears(state) {
      state.searchYears = []
    },

    resetSearchClients(state) {
      state.searchClients = []
    },

    resetPaymentsForCurrentClient(state) {
      state.paymentsForCurrentClient = {}
    },

    resetTrades(state) {
      state.trades = []
    },

    resetJobsources(state) {
      state.jobsources = []
    },

    resetOperativeFeeTotals(state) {
      state.taxfeetotal = '0.00'
      state.otherfeestotal = '0.00'
    },

    resetOperativesForCurrentClient(state) {
      state.operativesForCurrentClient = []
    },

    resetCurrentOperative(state) {
      state.currentOperativeDetails.ent_id = '-1'
      state.currentOperativeDetails.title = ''
      state.currentOperativeDetails.firstname = ''
      state.currentOperativeDetails.lastname = ''
      state.currentOperativeDetails.dob = 'new'
      state.currentOperativeDetails.status = ''
      state.currentOperativeDetails.ninumber = ''
      state.currentOperativeDetails.nationality = ''
      state.currentOperativeDetails.address = ''
      state.currentOperativeDetails.addressLine1 = ''
      state.currentOperativeDetails.addressLine2 = ''
      state.currentOperativeDetails.city = ''
      state.currentOperativeDetails.postcode = ''
      state.currentOperativeDetails.county = ''
      state.currentOperativeDetails.country = ''
      state.currentOperativeDetails.email = ''
      state.currentOperativeDetails.mobphone = ''
      state.currentOperativeDetails.homephone = ''
      state.currentOperativeDetails.name = ''
      state.currentOperativeDetails.updated = ''
      state.currentOperativeDetails.dayRate = ''
      state.currentOperativeDetails.comp_reg_no = ''
      state.currentOperativeDetails.comptype = ''
      state.currentOperativeDetails.trade = ''
      state.currentOperativeDetails.trade_id = -1
      state.currentOperativeDetails.trade_rate = ''
      state.currentOperativeDetails.sage_exp_enable = false
      state.currentOperativeDetails.jobsource_id_id = -1
      state.currentOperativeDetails.operativedetails = []
      state.currentOperativeDetails.operativedetails.push(new CreateOperativeDetails('', '', false, '', false, false, '', -1, 0, false, false))
      state.currentOperativeDetails.bankdetails = []
      state.currentOperativeDetails.bankdetails.push(new CreateBankDetails('', '', '', ''))
      state.currentOperativeDetails.cisdetails = []
      state.currentOperativeDetails.cisdetails.push(new CreateCISDetails(false, '', ''))
      state.currentOperativeDetails.gdprdetails = []
      state.currentOperativeDetails.gdprdetails.push(new CreateGDPRDetails(false))
      state.currentOperativeDetails.tradedetails = []
      state.currentOperativeDetails.tradedetails.push(new CreateTradeDetails(-1))
      state.currentOperativeDetails.entitysync = []
      state.currentOperativeDetails.entitysync.push(new CreateSageSyncDetails(''))
      state.currentOperativeDetails.vendordetails = []
      state.currentOperativeDetails.vendordetails.push(new CreateVendorDetails(''))
      state.currentOperativeDetails.entityclients = []
      state.currentOperativeDetails.entityclients.push(new CreateEntityClients(0))
    },

    approveCurrentOperative(state) {
      state.currentOperativeDetails.status = 'APPROVED'
    },

    setSyncSageValues(state) {
      //
      // Need to know rules on this info, assuming they don't change or shouldn't be changed once operative has been exported to Sage?
      //
      // if (state.currentOperativeDetails.operativedetails[0].sage_id.length === 0) {
      //   return
      // }

      // Always have a S sync record
      if (state.currentOperativeDetails.entitysync === undefined || state.currentOperativeDetails.entitysync.length === 0) {
        state.currentOperativeDetails.entitysync.push(new CreateSageSyncDetails(`S.${state.currentOperativeDetails.operativedetails[0].sage_id}`))
      } else {
        state.currentOperativeDetails.entitysync[0].sync_id = `S.${state.currentOperativeDetails.operativedetails[0].sage_id}`
      }

      // If Tax or Invoicing service, needs a C sync record
      if (state.currentOperativeDetails.operativedetails[0].sclinv === true || state.currentOperativeDetails.operativedetails[0].scltax === true) {
        // Only have 1 record in the array, need to add another array element for it and set the value
        if (state.currentOperativeDetails.entitysync === undefined || state.currentOperativeDetails.entitysync.length === 1) {
          state.currentOperativeDetails.entitysync.push(new CreateSageSyncDetails(`C.${state.currentOperativeDetails.operativedetails[0].sage_id}`))
        } else {
          state.currentOperativeDetails.entitysync[1].sync_id = `C.${state.currentOperativeDetails.operativedetails[0].sage_id}`
        }
      }
    },

    setOperativeSageId(state, sageid) {
      state.currentOperativeDetails.operativedetails[0].sage_id = sageid
    },
    setOperativeStatus(state, status) {
      state.currentOperativeDetails.status = status
    },
    setOperativeGdprSigned(state, gdprsigned) {
      state.currentOperativeDetails.gdprdetails[0].auth = gdprsigned
    },
    setOperativeClientId(state, clientId) {
      state.currentOperativeDetails.operativedetails[0].client_ent_id = clientId
    },

    setOperativeTitle(state, title) {
      state.currentOperativeDetails.title = title
    },
    setOperativeFirstname(state, firstname) {
      state.currentOperativeDetails.firstname = firstname
    },
    setOperativeLastname(state, lastname) {
      state.currentOperativeDetails.lastname = lastname
    },
    setOperativeDateOfBirth(state, dob) {
      state.currentOperativeDetails.dob = dob
    },
    setOperativeNINumber(state, ninumber) {
      state.currentOperativeDetails.ninumber = ninumber
    },
    setOperativeNationality(state, nationality) {
      state.currentOperativeDetails.nationality = nationality
    },
    setOperativeJobsourceId(state, jobsourceId) {
      state.currentOperativeDetails.jobsource_id_id = jobsourceId
    },
    setOperativeWorkPermit(state, wp) {
      state.currentOperativeDetails.operativedetails[0].workpermit = wp
    },
    setApplicationCSCS(state, cscs) {
      state.currentOperativeDetails.operativedetails[0].cscscard = cscs
    },
    setOperativeRTWApproved(state, rtwapproved) {
      state.currentOperativeDetails.operativedetails[0].rtwapproved = rtwapproved
    },

    setOperativeEmail(state, email) {
      state.currentOperativeDetails.email = email
    },
    setOperativeMobilePhone(state, mobphone) {
      state.currentOperativeDetails.mobphone = mobphone
    },
    setOperativeHomePhone(state, homephone) {
      state.currentOperativeDetails.homephone = homephone
    },

    setOperativeAddressLine1(state, address) {
      state.currentOperativeDetails.addressLine1 = address
      generalMethods.updateAddress(state.currentOperativeDetails, address, null, null)
    },

    setOperativeAddressLine2(state, address) {
      state.currentOperativeDetails.addressLine2 = address
      generalMethods.updateAddress(state.currentOperativeDetails, null, address, null)
    },

    setOperativeCity(state, city) {
      state.currentOperativeDetails.city = city
      generalMethods.updateAddress(state.currentOperativeDetails, null, null, city)
    },

    setOperativePostcode(state, postcode) {
      state.currentOperativeDetails.postcode = postcode
    },

    setOperativeTradeName(state, name) {
      state.currentOperativeDetails.operativedetails[0].tradename = name
    },
    setOperativeTradeId(state, tradeId) {
      state.currentOperativeDetails.trade_id = tradeId
    },
    setOperativeTradeRate(state, tradeRate) {
      state.currentOperativeDetails.trade_rate = tradeRate
    },
    setOperativeSageExportEnable(state, sageExportEnable) {
      state.currentOperativeDetails.sage_exp_enable = sageExportEnable
    },
    setOperativeVatReg(state, vatReg) {
      state.currentOperativeDetails.operativedetails[0].vatreg = vatReg
    },
    setOperativeVatNo(state, vat) {
      state.currentOperativeDetails.operativedetails[0].vatno = vat
    },
    setOperativeInvoiceService(state, hasInvoicing) {
      state.currentOperativeDetails.operativedetails[0].sclinv = hasInvoicing
    },
    setOperativeTaxService(state, hasTaxReturn) {
      state.currentOperativeDetails.operativedetails[0].scltax = hasTaxReturn
    },
    setOperativeCompanyRegNo(state, regNo) {
      state.currentOperativeDetails.comp_reg_no = regNo
    },
    setOperativeDayRate(state, rate) {
      state.currentOperativeDetails.dayRate = rate
    },
    setOperativeUtr(state, utr) {
      state.currentOperativeDetails.cisdetails[0].utr = utr
    },
    setOperativeCompanyType(state, companyType) {
      state.currentOperativeDetails.comptype = companyType
    },
    setOperativeCisReg(state, reg) {
      state.currentOperativeDetails.cisdetails[0].cisreg = reg
    },
    setOperativeTaxRate(state, taxrate) {
      state.currentOperativeDetails.cisdetails[0].taxrate = taxrate
    },
    setOperativeFeeOverride(state, fee) {
      if (fee === '') {
        state.currentOperativeDetails.operativedetails[0].feeoverride = 0
      } else {
        state.currentOperativeDetails.operativedetails[0].feeoverride = fee
      }
    },

    setOperativeBankAccountName(state, accname) {
      state.currentOperativeDetails.bankdetails[0].bnkaccname = accname
    },
    setOperativeBankSortcode(state, sortcode) {
      state.currentOperativeDetails.bankdetails[0].bnksortcode = sortcode
    },
    setOperativeBankAccNo(state, accno) {
      state.currentOperativeDetails.bankdetails[0].bnkaccno = accno
    },
    setOperativeBacsRef(state, bacsref) {
      state.currentOperativeDetails.bankdetails[0].bacsref = bacsref
    },

    initCurrentPaymentSearchCriteria(state) {
      if (state.currentPaymentSearchCriteria.selectedWeek > 0) return

      state.currentPaymentSearchCriteria.selectedClient = 'All clients'
      state.currentPaymentSearchCriteria.selectedWeekEnd = 'W/e xx/xx/xx'
      state.currentPaymentSearchCriteria.selectedWeek = getWeekOfYear()
      state.currentPaymentSearchCriteria.selectedYear = getCurrentYear(state.currentPaymentSearchCriteria.selectedWeek)
    },

    initOperativeSearchCriteria(state) {
      state.operativeSearchCriteria.selectedStatus = 'ACTIVE'
      state.operativeSearchCriteria.selectedClient = 'ALL'
      state.operativeSearchCriteria.selectedTrade = 'ALL'
      state.operativeSearchCriteria.selectedLastWorked = ''
      state.operativeSearchCriteria.limitToApplicants = false
    },

    updateClientPayBatchStatus(state, context) {
      let clientIndex = -1
      for (let index = 0; index < state.clients.length; index += 1) {
        clientIndex = index
        if (state.clients[index].ent_id === context.clientid) {
          break
        }
      }
      if (clientIndex < 0) {
        return
      }

      if (context.status < 0) {
        state.clients[clientIndex].paybatch[0].batch_id = ''
        state.clients[clientIndex].paybatch[0].status = -1
        state.clients[clientIndex].paybatch[0].filename = ''
        state.clients[clientIndex].paybatch[0].itemcount = 0
      } else {
        state.clients[clientIndex].paybatch[0].batch_id = context.batchid
        state.clients[clientIndex].paybatch[0].status = context.status
        state.clients[clientIndex].paybatch[0].filename = context.filename
        state.clients[clientIndex].paybatch[0].itemcount = context.itemcount
      }
    },

    updateClientWithBatchNo(state, context) {
      // const clientIndex = state.clients.findIndex(client => client.ent_id === context.clientid)
      let clientIndex = -1
      for (let index = 0; index < state.clients.length; index += 1) {
        clientIndex = index
        if (state.clients[index].ent_id === context.clientid) {
          break
        }
      }
      if (clientIndex < 0) {
        return
      }

      if (state.clients[clientIndex].paybatch.length === 0) {
        state.clients[clientIndex].paybatch.push(
          new CreatePayBatchDetails(
            context.batchid,
            context.date,
            context.filename,
            context.itemcount,
            context.status,
            context.week,
            context.year,
          ),
        )
      } else {
        state.clients[clientIndex].paybatch[0].date = context.date
        state.clients[clientIndex].paybatch[0].filename = context.filename
        state.clients[clientIndex].paybatch[0].id = context.batchid
        state.clients[clientIndex].paybatch[0].itemcount = context.itemcount
        state.clients[clientIndex].paybatch[0].status = context.status
        state.clients[clientIndex].paybatch[0].weekno = context.week
        state.clients[clientIndex].paybatch[0].taxyear_id = context.year
      }
    },

    updatePaymentStatus(state, context) {
      let clientIndex = -1
      for (let index = 0; index < state.paymentsForCurrentClient.payments.length; index += 1) {
        clientIndex = index
        if (state.paymentsForCurrentClient.payments[index].id === context.payment_id) {
          state.paymentsForCurrentClient.payments[index].status = context.status
          break
        }
      }

      if (clientIndex < 0) {
        console.error('payment NIF')
      }
    },

    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    resetClients(vuexContext, context) {
      vuexContext.commit('resetClients', context)
    },
    getClients(vuexContext, params) {
      vuexContext.commit('resetClients')
      vuexContext.commit('resetOperativesForCurrentClient')
      vuexContext.commit('resetCurrentOperative')

      jwt.axiosIns.get(`/uploadfile/${params}`)
        .then(response => {
          const data = JSON.parse(JSON.stringify(response.data))
          vuexContext.commit('getClients', data)
        })
        .catch(e => {
          console.error(e)
        })
    },

    getOperativesList(vuexContext, params) {
      vuexContext.commit('resetOperativesForCurrentClient')
      vuexContext.commit('resetCurrentOperative')
      jwt.axiosIns.get(`/operatives/${params}`)
        .then(response => {
          const data = JSON.parse(JSON.stringify(response.data))
          // console.log('operatives: ', data)
          vuexContext.commit('getOperativesForCurrentClient', data)
        })
        .catch(e => {
          console.error('operatives error: ', e)
        })
    },

    downloadOperativesList(vuexContext, params) {
      jwt.axiosIns.get(`/operativespdf/${params}`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.setAttribute('download', 'operativespdf-list.pdf')
          document.body.appendChild(downloadLink)
          downloadLink.click()
        })
        .catch(e => {
          console.error(e)
        })
    },

    getSearchYears(vuexContext) {
      vuexContext.commit('resetSearchYears')
      jwt.axiosIns.get('/taxyears/') // url??
        .then(response => {
          const data = JSON.parse(JSON.stringify(response.data))
          vuexContext.commit('getSearchYears', data)
        })
        .catch(e => {
          console.error(e)
        })
    },

    getSearchClients(vuexContext) {
      vuexContext.commit('resetSearchClients')
      jwt.axiosIns.get('/uploadfile/')
        .then(response => {
          const data = JSON.parse(JSON.stringify(response.data))
          vuexContext.commit('getSearchClients', data)
        })
        .catch(e => {
          console.error(e)
        })
    },

    getTrades(vuexContext) {
      vuexContext.commit('resetTrades')
      jwt.axiosIns.get('/trades/')
        .then(response => {
          vuexContext.commit('getTrades', response.data)
        })
        .catch(e => {
          console.error(e)
        })
    },

    getJobsources(vuexContext) {
      vuexContext.commit('resetJobsources')
      jwt.axiosIns.get('/jobsources/')
        .then(response => {
          // console.log('getJobsources:', response)
          vuexContext.commit('getJobsources', response.data)
        })
        .catch(e => {
          console.error(e)
        })
    },

    getFeeTotalsForCurrentClient(vuexContext, params) {
      vuexContext.commit('resetOperativeFeeTotals')

      jwt.axiosIns.get(`/operativefees/${params.ent_id}/${params.taxyear_id}/`)
        .then(response => {
          const data = JSON.parse(JSON.stringify(response.data))
          vuexContext.commit('getOperativeFeeTotals', data)
        })
        .catch(e => {
          console.error(e)
        })
    },

    resetOpertivesForCurrentClient(vuexContext, context) {
      vuexContext.commit('resetOperativesForCurrentClient', context)
    },
    resetCurrentOperative(vuexContext, context) {
      vuexContext.commit('resetCurrentOperative', context)
    },

    getCurrentOperative(vuexContext, context) {
      vuexContext.commit('resetCurrentOperative', context)

      jwt.axiosIns.get(`/operative/${context.operativeid}/`)
        .then(response => {
          vuexContext.commit('getCurrentOperative', response.data)
          const [addressLine1, addressLine2, city] = response.data.address.toString().split(/\n/)
          vuexContext.commit('setOperativeAddressLine1', addressLine1)
          vuexContext.commit('setOperativeAddressLine2', addressLine2)
          vuexContext.commit('setOperativeCity', city)
        })
        .catch(e => {
          console.error(e)
        })
    },

    saveCurrentOperative(vuexContext, context) {
      if (vuexContext.state.currentOperativeDetails.sage_exp_enable === true) {
        vuexContext.commit('setSyncSageValues') // Ensure sage sync setup
      }

      const data = JSON.stringify(vuexContext.state.currentOperativeDetails)
      /* eslint-disable */
      jwt.axiosIns.put(`/operative/${context.operativeid}/`, data )
      /* eslint-enable */
        .then(response => {
          if (response.data.message !== undefined) {
            generalMethods.failedMessage(response.data.message)
          } else {
            generalMethods.successMessage('Success!', `Operative '${vuexContext.state.currentOperativeDetails.firstname} ${vuexContext.state.currentOperativeDetails.lastname}' has been saved.`)
            router.go(-1) // Return to previous page
          }
        })
        .catch(e => {
          try {
            let message = 'There was an error saving the Operative. Please review for missing fields'
            if (e.response.data.operativedetails[0].sage_id !== undefined) {
              message = e.response.data.operativedetails[0].sage_id
            }
            generalMethods.failedMessage(message)
          } catch {
            generalMethods.failedMessage('There was an error saving the Operative. Please review for missing fields')
          }
        })
    },

    deleteFile(vuexContext, context) {
      const data = new FormData()
      data.append('batchid', context.batchid)
      jwt.axiosIns.put('/deletefile/', data)
        .then(response => {
          if (response.data.result === 'success') {
            vuexContext.commit('updateClientPayBatchStatus', {
              batchid: context.batchid,
              status: response.data.status,
              clientid: response.data.clientid,
            })
            generalMethods.successMessage('Success!', 'Payment batch has been deleted.')
            router.push('/')
          } else {
            generalMethods.failedMessage(response.data.message)
          }
        })
        .catch(e => {
          console.error(e)
        })
    },

    async generateBACS(vuexContext, context) {
      jwt.axiosIns.get(`/generatebacs/${context.batchid}/`, { responseType: 'blob' })
        .then(response => {
          let fileExtension = '.csv'
          if (response.data.type === 'application/ms-excel') {
            fileExtension = '.xls'
          }
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.setAttribute('download', `bacsfile-${context.batchid}${fileExtension}`)
          document.body.appendChild(downloadLink)
          downloadLink.click()
          generalMethods.successMessage('Success!', 'BACS file downloaded')
          router.push('/')
        })
        .catch(e => {
          const { data } = e.response
          const fileRead = new FileReader()
          fileRead.readAsText(data)
          fileRead.onload = function extractMessage() {
            const { message } = JSON.parse(fileRead.result)
            let mess = ''
            if (Array.isArray(message)) {
              mess = 'BACS file generation failed with these error(s)...<br><br>'
              for (let index = 0; index < message.length; index += 1) {
                mess += `<b>${index + 1}: </b>${message[index].errors[0].message}<br>`
              }
            } else {
              mess = message
            }
            generalMethods.failedMessage(mess)
          }
        })
    },

    submitToSage(vuexContext, context) {
      jwt.axiosIns.put(`/markbatchforsage/${context.batchid}/`)
        .then(response => {
          if (response.data.result === 'success') {
            generalMethods.successMessage('Success!', 'Payments queued for Sage')
            router.push('/')
          } else {
            let mess = ''
            if (Array.isArray(response.data.message)) {
              mess = 'Send to Sage failed with these error(s)...<br><br>'
              for (let index = 0; index < response.data.message.length; index += 1) {
                mess += `<b>${index + 1}: </b>${response.data.message[index].errors[0].message}<br>`
              }
            } else {
              mess = response.data.message
            }
            generalMethods.failedMessage(mess)
          }
        })
        .catch(e => {
          console.error(e)
          generalMethods.failedMessage('Failed on queuing payments for Sage')
        })
    },

    logoutAndReset(vuexContext) {
      vuexContext.commit('resetCurrentYear')
      vuexContext.commit('resetCurrentWeek')
      vuexContext.commit('resetClients', '')
      vuexContext.commit('resetOperativesForCurrentClient', '')
      vuexContext.commit('resetCurrentOperative', '')
      vuexContext.commit('resetTrades', '')
      vuexContext.commit('clearToken')
      router.push('/login')
    },

    setAccessTokenTimer(vuexContext) {
      setTokenTimeout(vuexContext, true)
    },

    setRefreshTokenTimer(vuexContext) {
      setTokenTimeout(vuexContext, false)
    },

    setLogoutTimerXXX(vuexContext, duration) {
      setTimeout(() => {
        vuexContext.commit('resetCurrentYear')
        vuexContext.commit('resetCurrentWeek')
        vuexContext.commit('resetClients', '')
        vuexContext.commit('resetOperativesForCurrentClient', '')
        vuexContext.commit('resetCurrentOperative', '')
        vuexContext.commit('resetTrades', '')
        jwt.axiosIns.post('/logout/')
          .then(() => {
          })
          .catch(e => {
            console.error(e)
            generalMethods.warningMessage('Your session has timed out')
            vuexContext.commit('clearToken')
            router.push('/login')
          })

        generalMethods.warningMessage('Your session has timed out')
        vuexContext.commit('clearToken')
        router.push('/login')
      }, duration)
    },

    /*
    initAuth(vuexContext) {
      const token = localStorage.getToken()
      const expirationDate = localStorage.getItem('tokenExpiration')
      if (new Date().getTime() > +expirationDate || !token) {
        return
      }
      vuexContext.dispatch('setLogoutTimer', +expirationDate - new Date().getTime())
    },
    */
    /*
    userLogin(context, userDetails) {
      return new Promise((resolve, reject) => {
        const getAPI = axios.create({
          baseURL: 'http://localhost:8000',
          timeout: 1000,
        })
        getAPI.post('/token/', {
          username: userDetails.username,
          password: userDetails.password,
        })
          .then(response => {
            context.commit('updateStorage', { access: response.data.access, refresh: response.data.refresh })
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    */
  },
}
